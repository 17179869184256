import React from 'react';
import { Link } from 'gatsby';

import '../../styles/common/pagination.scss';

export default (props) => {
  let pageLinks = [];

  if (props.page > 1) {
    pageLinks.push(
      <div key="prev-page-link">
        <Link
          to={`${ props.pathStem }${ props.page - 1 }`}
        >
          «
        </Link>
      </div>
    );
  }

  if (props.page > 3) {
    pageLinks.push(
      <div key="first-page-link">
        <Link
          to={`${props.pathStem}1`}
        >
          1
        </Link>
      </div>
    );
    pageLinks.push(
      <div key="front-link-spacer">
        <span
          className="spacer"
        >
          …
        </span>
      </div>
    );
  }

  let firstPageIndex = (props.page > 3) ? props.page - 1 : 1;
  let lastPageIndex = (props.page < props.totalPages - 1) ? props.page + 1 : props.totalPages;

  for (let i = firstPageIndex; i <= lastPageIndex; i++) {
    pageLinks.push(
      <div key={`page-link-${i}`}>
        <Link
          to={`${ props.pathStem }${ i }`}
          className={(props.page === i) ? 'here' : ''}
        >
          { i }
        </Link>
      </div>
    );
  }

  if (props.page < props.totalPages - 2) {
    pageLinks.push(
      <div key="back-link-spacer">
        <span
          className="spacer"
        >
          …
        </span>
      </div>
    );
  }

  if (props.page < props.totalPages - 1) {
    pageLinks.push(
      <div key="last-page-link">
        <Link
          to={`${ props.pathStem }${ props.totalPages }`}
        >
          { props.totalPages }
        </Link>
      </div>
    );
  }

  if (props.page < props.totalPages) {
    pageLinks.push(
      <div key="last-page-link">
        <Link
          to={`${ props.pathStem }${ props.page + 1 }`}
        >
          »
        </Link>
      </div>
    );
  }

  if (pageLinks.length > 1) {
    return (
      <div className="pagination">
        {pageLinks.map(link => link)}
      </div>
    );
  }

  return null;
};