import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BigButton from '../common/big-button';
import PageColumn from '../layout/page-column';
import RainbowBar from '../common/rainbow-bar';
import Section from '../layout/section';
import SectionHeader from '../common/section-header';
import TaxonomyGrid from '../common/taxonomy/grid';

export default (props) => {
  const data = useStaticQuery(graphql`
    query AuthorsQuery {
      authors:allAirtable(filter:{table:{eq:"Authors"}},
                    sort:{fields: data___Sort_Name, order:ASC}) {
        edges {
          node {
            data {
              Name
              Slug
              Books {
                data {
                  Slug
                }
              }
            }
          }
        }
      }
    }
  `);
  let selectedAuthorNodes = data.authors.edges.map(({ node }) => node);
  if (props.excludeAuthor) {
    selectedAuthorNodes = selectedAuthorNodes.filter(node => node.data.Slug !== props.excludeAuthor);
  }
  selectedAuthorNodes = selectedAuthorNodes.sort((a, b) => {
    if (!a.data.Books) return 1;
    if (!b.data.Books) return -1;

    return b.data.Books.length - a.data.Books.length;
  }).slice(0, 9);

  return (
    <>
    <RainbowBar width='thin' />
    <Section topSpacing="section" color="light-gray">
      <PageColumn>
        <SectionHeader label="Top Authors" />
        <TaxonomyGrid
          nodes={ selectedAuthorNodes }
          labelProperty="Name"
          pathStem="/author"
        />
      </PageColumn>
    </Section>
    <Section topSpacing="subsection" bottomSpacing="section" color="light-gray">
      <PageColumn>
        <BigButton
          to="/authors"
          label="All Authors"
        />
      </PageColumn>
    </Section>
    </>
  );
}
